<template>
  <v-simple-table fixed-header class="pt-1">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left caption">Image</th>
          <th class="text-left caption">Title</th>
          <th class="text-left caption">Topics</th>
          <!-- <th class="text-left caption">Duration</th> -->
          <th class="text-left caption">Added</th>
          <th class="text-left caption">
            Row count
            {{
              $store.getters["teacher/countGetters"] == 0
                ? data.length
                : `${$store.getters["teacher/coursesGetters"].to} of ${$store.getters["teacher/coursesGetters"].total}`
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td>
            <v-avatar color="success" size="36">
              <v-img
                src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
              />
            </v-avatar>
          </td>
          <td class="body-2">{{ item.title }}</td>
          <td class="body-2">{{ item.lessons_count }}</td>
          <!-- <td class="body-2">
            {{ item.duration_type + " " + item.duration_count }}
          </td> -->
          <td class="body-2">{{ $helpers.dateDiff(item.created_at) }}</td>
          <td>
            <v-btn icon>
              <v-icon size="18">mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn icon class="mx-2">
              <v-icon size="18">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn icon @click="$emit('delete', item.id)">
              <v-icon size="18">mdi-delete-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: ["data"],
};
</script>