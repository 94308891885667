<template>
  <v-card flat max-width="230">
    <v-card-text>
      <v-img
        v-if="item.image"
        height="150"
        max-height="150"
        :src="`${item.aws}/courses/${item.uuid}/image/${item.image.image}`"
      />

      <v-avatar
        tile
        v-else
        height="150"
        width="100%"
        color="grey"
      ></v-avatar>

      <!-- <v-list-item-title>
        
      </v-list-item-title> -->
      <div
          class="body-1 mt-3 font-weight-bold"
          :class="dark == 1 ? 'white--text' : ''"
        >
          {{ item.title }}
        </div>
      <div
        v-if="!more"
        class="caption"
        v-html="item.description"
        style="height: 20px; overflow: hidden"
      />
      <div v-else class="caption" v-html="item.description" />
      <div
        style="cursor: pointer"
        @click="more = !more"
        class="info--text"
        v-if="item.description"
      >
        Read{{ more ? " short" : " more..." }}
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn
        color="primary"
        block
        :to="{ name: 'Teacher Edit Course', params: { uuid: item.uuid } }"
      >
        Course Overview
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["item", "dark"],
  data: () => ({
    more: false,
  }),
};
</script>