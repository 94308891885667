<template>
  <v-dialog persistent max-width="440" v-model="dialog">
    <v-card rounded>
      <v-card-title
        class="d-flex justify-space-between align-center primary white--text"
      >
        <div class="">New Course</div>
        <v-btn icon small @click="$emit('close')">
          <v-icon color="white" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="addForm" @submit.prevent="save" class="mt-5 mx-2">
          <label class="caption">TITLE *</label>
          <v-text-field v-model="form.title" :error-messages="errors.title" />
          <!-- <v-row>
            <v-col cols="6">
              <label class="caption">DURATION TYPE *</label>
              <v-select
                v-model="form.duration_type"
                :error-messages="errors.duration_type"
                :items="$helpers.durations()"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <label class="caption">DURATION COUNT *</label>
              <v-text-field
                type="number"
                :error-messages="errors.duration_count"
                v-model="form.duration_count"
              />
            </v-col>
          </v-row> -->
          <label class="caption">DESCRIPTION</label>
          <v-textarea v-model="form.description"></v-textarea>
          <v-btn
            block
            large
            color="primary"
            dark
            outlined
            :type="!loading ? 'submit' : ''"
            class="mt-2"
            >{{ !loading ? "Submit" : "Submitting..." }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["dialog"],
  data: () => ({
    loading: false,
    form: {
      title: "",
      description: "",
      duration_type: "",
      duration_count: "",
    },
  }),
  computed: {
    ...mapState("teacher", {
      errors: (state) => state.errors,
    }),
  },
  methods: {
    save() {
      console.log(this.form);
      this.$store.dispatch("teacher/addCourseAction", this.form).then(() => {
        if (this.$errors("teacher")) {
          console.log("error");
          return;
        }
        this.$emit("close");
      });
    },
  },
};
</script>