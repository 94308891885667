<template>
  <section id="student-account">
    <v-row v-if="!loading">
      <v-col cols="12" xl="9" lg="11">
        <div class="text-h5 ml-2 mb-5">Manage Courses</div>

        <Dashboard />
        <v-row no-gutters class="mb-0 pb-0 mt-16">
          <v-col cols="12" lg="3">
            <v-text-field
              solo
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              v-model="$store.state[store].query"
              v-on:input="search($event)"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              solo
              prepend-inner-icon="mdi-calendar"
              :items="$helpers.showDate()"
              v-model="$store.state[store].date"
              v-on:input="searchDate($event)"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              solo
              prepend-inner-icon="mdi-account-multiple-outline"
              :items="$helpers.showPage()"
              item-text="text"
              item-value="value"
              v-model="$store.state[store].count"
              v-on:input="searchCount($event)"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-btn @click="reset" small class="mt-1 mx-2" color="warning" fab>
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-btn
              @click="dialog = !dialog"
              small
              class="mt-1 mr-2"
              color="primary"
              fab
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              @click="compact = !compact"
              small
              class="mt-1"
              color="info"
              fab
            >
              <v-icon>{{
                !compact ? "mdi-view-grid" : "mdi-format-list-bulleted"
              }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="10">
            <v-expand-transition>
              <v-card v-if="!compact" rounded="lg" color="transparent">
                <Table
                  v-if="$store.getters['teacher/countGetters'] > 0"
                  :data="courses.data"
                  @delete="remove"
                />
                <Table v-else :data="courses" @delete="remove" />
              </v-card>
              <v-row v-else>
                <v-col cols="12" lg="3" v-for="item in courses.data" :key="item.id">
                  <Card :item="item" :dark="user.dark" />
                </v-col>
              </v-row>
              
            </v-expand-transition>

            <course-paginate
              v-if="courses.total > 0"
              :store="store"
              collection="courses"
            />

            <div v-else class="text-center body-1 mt-5">
              {{ courses ? "" : "No data found" }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="body-2" v-else>Collecting Data...</div>
    <AddCourse :dialog="dialog" @close="dialog = !dialog" />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Table from "../../components/course/Table.vue";
import Card from "../../components/course/Card.vue";
import AddCourse from "../../components/course/AddCourse.vue";
import Dashboard from "../Dashboard.vue";

export default {
  components: {
    Table,
    Card,
    AddCourse,
    Dashboard,
  },
  data: () => ({
    store: "teacher",
    loading: true,
    dialog: false,
    snack: false,
    snackText: "",
    compact: true,
  }),
  computed: {
    ...mapState("teacher", {
      courses: (state) => state.courses,
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions("teacher", ["getCoursesAction"]),

    fetch() {
      this.getCoursesAction({
        page: 0,
        query: "",
        date: "",
        count: 10,
      }).then(() => {
        this.loading = false;
      });
    },
    search(event) {
      var vm = this;
      vm.getCoursesAction({
        page: 0,
        query: event,
        date: vm.$store.state[vm.store].date,
        count: vm.$store.state[vm.store].count,
      });
    },
    searchDate(event) {
      var vm = this;
      vm.getCoursesAction({
        page: 0,
        query: vm.$store.state[vm.store].query,
        date: event,
        count: vm.$store.state[vm.store].count,
      });
    },
    searchCount(event) {
      var vm = this;
      vm.getCoursesAction({
        page: 0,
        query: vm.$store.state[vm.store].query,
        date: vm.$store.state[vm.store].date,
        count: event,
      });
    },
    reset() {
      this.$store.commit("teacher/resetPageMutation");
      this.fetch();
    },
  },
};
</script>